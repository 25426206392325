import { ConfigProvider } from "antd";
import CardImg from "assets/3free_img.png";
import FacebookImg from "assets/facebook2.png";
import LinkIcon from "assets/link.svg";
import LinkedinImg from "assets/linkedin2.png";
import TwitterImg from "assets/twitter2.png";
import { varIs, varLabel } from "common/var";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Progress,
  Row,
  SuccessNotification,
  TablePanel,
  Tag,
} from "components/common";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { callGetApiWithAuth } from "utils/api";
import { asPrice, asDate } from "utils/text";

export default function ThreeFreePage() {
  const myUser = useSelector((state) => state.auth.user);
  const theme = useSelector((state) => state.ui.theme);
  // const [qualUsers, setQualUsers] = useState([]);
  // const [subscriptions, setSubscriptions] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [searchParam, setSearchParam] = useState({
    date: moment().format("YYYY-MM"),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [subscription, setSubscription] = useState();
  const [stats, setStats] = useState({
    total_order_amount: 0,
    qualified_amount: 0,
    pe_count: 0,
  });

  const [description, setDescription] = useState("");

  const onFormInput = (field, value) => {
    setSearchParam({
      ...searchParam,
      [field]: value,
    });
  };

  const onGetTableData = (res) => {
    setIsLoading(false);
    setSubscription(res.data.subscription);
    setTableData(res.data.data);
    setStats({
      total_order_amount: res.data.stats.total_order_amount,
      qualified_amount: res.data.stats.qualified_amount,
      pe_count: res.data.data.length,
    });
  };

  const onFailTableData = () => {
    setIsLoading(false);
  };

  const loadTableData = (searchParam0) => {
    setIsLoading(true);

    const params = {
      ...searchParam0,
    };

    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");

    callGetApiWithAuth(
      `akashx/home/three_free_track_new?${q}`,
      onGetTableData,
      onFailTableData
    );
  };

  const handleSearch = () => {
    loadTableData(searchParam);
  };

  useEffect(() => {
    setDescription(handleContent());
  }, [subscription, stats]);

  const handleContent = () => {
    if (!subscription) {
      return "Sorry. You don't have Akashx membership yet.";
    }

    if (subscription.status !== 1) {
      return "You're so close. You need an active Akashx membership.";
    }

    if (stats.pe_count < 3) {
      if (stats.total_order_amount < stats.qualified_amount) {
        return `You’re so close. You need ${3 - stats.pe_count
          } more members and ${stats.qualified_amount - stats.total_order_amount
          } in personally enrolled volume to qualify!`;
      }

      return `You’re so close. You need ${3 - stats.pe_count} more members.`;
    }

    if (stats.total_order_amount < stats.qualified_amount) {
      return `You’re so close. You need ${stats.qualified_amount - stats.total_order_amount
        } in personally enrolled volume to qualify!`;
    }

    return `Congratulations 🎉 you’re qualified!`;
  };

  useEffect(() => {
    handleSearch();
  }, []);

  // const onGetFree3Data = ({ data }) => {
  //   setQualUsers([...data.qual_users]);
  //   setSubscriptions([...data.subscriptions]);
  // };
  // const getFree3Data = () => {
  //   callGetApiWithAuth(`akashx/home/three_free_track_new`, onGetFree3Data);
  // };

  const handleCopy = () => {
    navigator.clipboard.writeText(`https://www.akashx.com/${myUser?.username}`);
    SuccessNotification("Copied on clipboard");
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     $('.owl-banner').owlCarousel({
  //       loop: true,
  //       autoplay:true,
  //       slideTransition: 'linear',
  //       autoplayHoverPause: false,
  //       items: 1,
  //     })
  //   }, [1000])
  // }, [])

  // useEffect(() => {
  //   getFree3Data();
  // }, []);

  return (
    <Wrapper>
      {/* <div className='banner-card'>
        <div className="owl-carousel owl-theme owl-banner">
          <div className="item">
            <img src={BannerImg} />
          </div>
        </div>
      </div> */}
      <div className="site-card">
        <div className="img-wrap">
          <img src={CardImg} />
        </div>
        <div className="desc-wrap">
          <h4>Refer 3+ Friends, Get a Free Membership:</h4>
          <p>
            When you refer at least 3 friends to join Akashx and their total
            order amount is over 3 times of your subscription price, you'll
            receive a FREE membership! Refer 3+ friends to Akashx who pay the
            $149 monthly membership and your next billing date for Akashx is
            waived.
          </p>
          <p>
            It's that simple. Spread the word, share the trading excitement, and
            enjoy the perks of a complimentary membership.
          </p>
          <div className="lnk-wrap">
            <p className="lnk-span">
              <label>Your Website Link</label>
              <br />
              <span>{`https://www.akashx.com/${myUser?.username}`}</span>
            </p>
            <button onClick={handleCopy}>
              <img src={LinkIcon} />
              Website Link
            </button>
          </div>
          <div className="social-wrap">
            <img src={FacebookImg} />
            <img src={LinkedinImg} />
            <img src={TwitterImg} />
          </div>
        </div>
      </div>

      <div className="free3-card">
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <Alert
              style={{
                margin: "5px 0px 5px",
                alignItems: "baseline",
                borderRadius: "8px",
              }}
              message={
                <div className="rank-alert">
                  <span>{description}</span>
                </div>
              }
              type={"warning"}
              showIcon
            />
          </Col>
          <Col xs={24} sm={12}>
            <div className="rank-progress-container">
              <div className="progress-label-container">
                <div className="label">Your Subscription</div>
                <div className="d-flex align-items-center gap-1">
                  <div className="value">{subscription?.product.title}</div>
                  <div className="payment-tag">
                    {varIs(
                      "subscription.status",
                      subscription?.status,
                      "active"
                    ) ? (
                      <Tag color="#87d068" className="active">
                        Active
                      </Tag>
                    ) : (
                      <Tag color="red" className="failed">
                        {varLabel("subscription.status", subscription?.status)}
                      </Tag>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12}>
            <div className="rank-progress-container">
              <div className="progress-label-container">
                <div className="label">Next Billing Date</div>
                <div className="value">{subscription?.next_date}</div>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12}>
            <div className="rank-progress-container">
              <div className="progress-label-container">
                <div className="label">Personally Enrolled</div>
                <div className="value">({stats.pe_count}/3)</div>
              </div>
              <div className="rank-progress">
                <Progress
                  percent={100 * (stats.pe_count > 3 ? 1 : stats.pe_count / 3)}
                  showInfo={false}
                  strokeColor="#c1954a"
                  strokeWidth={10}
                />
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12}>
            <div className="rank-progress-container">
              <div className="progress-label-container">
                <div className="label">Personally Enrolled Volume</div>
                <div className="value">
                  ({stats.total_order_amount}/{stats.qualified_amount})
                </div>
              </div>
              <div className="rank-progress">
                <Progress
                  percent={
                    100 *
                    (stats.total_order_amount > stats.qualified_amount
                      ? 1
                      : stats.total_order_amount / stats.qualified_amount)
                  }
                  showInfo={false}
                  strokeColor="#c1954a"
                  strokeWidth={10}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="table-wrap">
        <TablePanel
          data={tableData}
          loading={isLoading}
          toolbar={
            <div
              className="d-flex align-items-center flex-wrap"
              style={{ gap: "8px" }}
            >
              <ConfigProvider theme="dark">
                <DatePicker
                  onChange={(_, v) => { if (v === '') return; onFormInput("date", v) }}
                  picker="month"
                  value={moment(searchParam.date)}
                />
              </ConfigProvider>

              <Button loading={isLoading} onClick={handleSearch}>
                Search
              </Button>
            </div>
          }
          columns={[
            {
              title: "User ID",
              key: "uuid",
              render: (_, record) => <span>{record.uuid}</span>,
            },
            {
              title: "Name",
              key: "user",
              render: (_, record) => <span>{record.user}</span>,
            },
            {
              title: "Username",
              key: "username",
              render: (_, record) => <span>{record.username}</span>,
            },
            {
              title: "Product",
              key: "product",
              render: (_, record) => <span>{record.product}</span>,
            },
            {
              title: "Amount",
              key: "amount",
              render: (_, record) => <span>{asPrice(record.amount)}</span>,
            },
            {
              title: "Paid At",
              key: "paid_at",
              render: (_, record) => <span>{asDate(record.paid_at)}</span>,
            },
          ]}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .free3-card {
    border-radius: 20px;
    background: ${(props) => props.theme.palette.cardBg};
    border: 1px solid ${(props) => props.theme.palette.cardBorder};
    padding: 23px;
    margin: 20px 0;
    height: 100%;
  }
  .site-card {
    border-radius: 20px;
    background: ${(props) => props.theme.palette.cardBg};
    border: 1px solid ${(props) => props.theme.palette.cardBorder};
    padding: 25px 20px;
    margin-bottom: 20px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .img-wrap {
      width: 800px;
      @media (max-width: 1366px) {
        width: 400px;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
    .desc-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 20px 0px;
      width: calc(100% - 840px);
      @media (max-width: 1366px) {
        width: calc(100% - 440px);
      }
      @media (max-width: 768px) {
        width: 100%;
        margin-top: 12px;
      }
      h4 {
        color: ${(props) => props.theme.palette.text};
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 9px;
      }
      p {
        color: ${(props) => props.theme.palette.text};
        font-size: 15px;
        margin-bottom: 10px;
        line-height: 22px;
        font-weight: 400;
      }
      .lnk-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        label {
          color: ${(props) => props.theme.palette.secondaryText};
        }
      }
      button {
        border-radius: 12px;
        background: #c1954a;
        width: 140px;
        padding: 8px 0;
        border: 0 none;
        color: #0d0d0d;
        font-size: 14px;
        img {
          margin-right: 8px;
        }
      }
    }
    .social-wrap {
      display: flex;
      margin: 8px 0;
      img {
        margin-right: 8px;
        cursor: pointer;
      }
    }
  }
  .table-wrap {
    .cardRoot {
      color: ${(props) => props.theme.palette.text};
      border-radius: 15px;
      background: ${(props) => props.theme.palette.cardBg};
    }
    // .panelHead {
    //   display: none;
    // }

    // .tableWrapper {
    //   margin-top: -20px;
    // }

    .ant-table thead tr th {
      background: #2d2921;
      color: ${(props) => props.theme.palette.th};
    }
    .ant-table {
      color: ${(props) => props.theme.palette.text};
      background-color: transparent;
    }
    .tableWrapper .ant-table-tbody > tr.ant-table-row:hover > td {
      background-color: transparent;
      opacity: 0.8;
    }
    .ant-table-thead > tr > th {
      border-color: #efedf91a;
    }
    .ant-table-tbody > tr > td {
      border-color: #efedf91a;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: transparent;
    }
    .ant-table-tbody > tr.ant-table-placeholder:hover > td {
      background: transparent;
    }
  }
  .line-image {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .free-member-ship {
    display: flex;
  }

  .rank-progress-container {
    // margin-top: 20px;
    .progress-label-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label {
        font-weight: normal;
        font-size: 14px;
        color: #7f8fa3;
      }
      .value {
        font-weight: 600;
        font-size: 14px;
        color: #c1954a;
      }
      .payment-tag {
        .active {
          background: rgba(0, 171, 85, 0.08) !important;
          border-radius: 6px;
          font-weight: 700;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #36b37e;
        }
        .failed {
          background: rgba(255, 86, 48, 0.08) !important;
          border-radius: 6px;
          font-weight: 700;
          border: none;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #ff5630;
        }
      }
    }
    .rank-progress {
      .ant-progress-inner,
      .ant-progress-bg {
        border-radius: 0px;
      }
    }
  }
`;
